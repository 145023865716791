@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    /* position: fixed; */
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 10;
  }
}

.container {
  width: 100%;
  background: var(--Pebble, #f7f6f5);
  min-height: calc(100vh - 72px);
  /* margin-top: 80px; */
}

.searchContents {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;

  @media (max-width: 1700px) {
    padding: 0 22px;
  }

  @media (max-width: 1024px) {
    padding: 0 24px !important;
  }

  @media (max-width: 768px) {
    padding: 0 16px !important;
  }

  @media (min-width: 1700px) {
    /* margin-left: auto;
    margin-right: auto;
    max-width: 1160px; */
    padding: 0;
  }

  & :global(.ais-Pagination-item--firstPage) {
    display: none;
  }

  & :global(.ais-Pagination-item--lastPage) {
    display: none;
  }
}

.searchRow {
  display: grid;
  grid-template-columns: 48% 52%;
  gap: 75px;
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 18px 0 0 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
    padding: 23px 0 0 0;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

/* .searchFiltersPrimary {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    padding: 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
} */

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  /* padding: 0 24px 24px 24px; */

  @media (--viewportLarge) {
    /* padding: 0 36px 36px 36px; */
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
  margin-top: 100px;
}

.mainWrapper {
  background: #fcfcfc;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  composes: button marketplaceTinyFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  /* width: 100vw; */
  height: 100vh;

  & .canvas {
    width: 100%;
  }
}

.mapBox {
  position: relative;

  & .searchInput {
    position: absolute;
    z-index: 9;
    width: 628px;
    margin: auto;
    left: 0;
    right: 0;
    top: 35px;
    background: #ffffff;
    border: 0.5px solid #939393;
    box-shadow: 0px 3px 4px rgb(0 0 0 / 14%), 0px 3px 3px rgb(0 0 0 / 12%),
      0px 1px 8px rgb(0 0 0 / 20%);
    border-radius: 4px;
  }
}

.ais-InstantSearch__root {
  display: flex;
  flex-direction: column;
}

.ais-SearchBox {
  margin: 10px 0;
}

.ais-GeoSearch {
  padding-right: 0;
  padding-left: 0;
  height: 700px;
}

.marker {
  transform: translate(-50%, -100%) scale(0.5, 0.5);
}

/* .notLoginUserSearch {
  margin-top: 80px;
} */
.test {
  display: none;
}

.searchWrapper {}

.categoryName {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding-top: 0.5rem;

  @media (max-width: 576px) {
    padding-top: 0rem;
  }

  & h3 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 600;
    font-family: 'bon-vivant';

    @media (max-width: 576px) {
      margin-top: 0rem;
    }
  }
}

.aisInstantSearch {
  display: flex;
  margin-bottom: 50px;
  gap: 74px;
  margin-top: 200px;
  max-width: 1520px;
  width: 100%;
  margin: 2.5rem auto;

  @media (max-width: 1023px) {
    gap: 2.5rem;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }

  & .fullLeftPanel {
    width: 100% !important;
  }

  & .leftPanel {
    width: 50%;
    padding-top: 17px;

    @media (max-width: 768px) {
      padding: 0;
    }

    @media (max-width: 1400px) {
      width: 60%;
    }

    @media (max-width: 1300px) {
      width: 100%;
    }

    & .dividerLine {
      background-color: #e0e0e0;
      height: 1px;
      width: 100%;
    }

    & .paginatgionBottom {
      margin-top: 57px;
    }

    & .searchFilter {
      display: flex;
      gap: 20px;
      margin-bottom: 35px;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        margin-bottom: 20px;
      }

      &>div {
        &:nth-child(1) {
          @media (max-width: 460px) {
            width: 100%;
          }
        }

        &:nth-child(2) {
          @media (max-width: 600px) {
            width: 75%;
          }
        }
      }

      & form {
        & button {
          &:nth-child(2) {
            /* left: 1.3rem; */

            & svg {
              width: 21px;
              height: 21px;

              & path {
                fill: #23263b;
              }
            }
          }
        }

        & input {
          background: #ffffff;
          height: 51px;
          border: 0.5px solid #939393;
          border-radius: 4px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #939393;
          width: 362px;
          padding: 14px 14px 14px 46px;

          @media (max-width: 460px) {
            width: 100%;
          }
        }
      }

      & .filterBox {
        cursor: pointer;
      }

      & .dateSelect {
        & select {
          background-position: 95% 17px;
          border: 0.5px solid #939393;
          border-radius: 4px;
          width: 289px;
          height: 51px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #939393;
          padding: 14px;
        }
      }
    }

    & .filterClearButton {
      padding-bottom: 0px;

      @media (max-width: 768px) {
        white-space: nowrap;
      }

      & button {
        border-radius: 4px;
        padding: 7px 20px 7px 20px;
        line-height: 1;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: center right 16px;
        background-size: 10px;
        text-align: center !important;
        margin: 0;
        background-color: #fff;
        border: 1px solid #d8dce6;
        outline: none;
        transition: all ease-in-out 100ms;
        box-shadow: 0 2px 3px 0 rgba(216, 220, 230, 0.7);
        appearance: none;
        background-image: none;
        color: #4a4a4a;
        font-weight: 400;
        cursor: pointer;
      }
    }

    & .fullCardGrid {
      & ul {
        grid-template-columns: repeat(3, 1fr) !important;

        @media (max-width: 1200px) {
          grid-template-columns: repeat(3, 1fr) !important;
        }

        @media (max-width: 800px) {
          grid-template-columns: repeat(2, 1fr) !important;
        }

        @media (max-width: 500px) {
          grid-template-columns: repeat(1, 1fr) !important;
        }
      }
    }

    & .cardsGrid {
      margin-top: 35px;

      & ul {
        display: grid;
        column-gap: 46px;
        row-gap: 45px;
        margin-top: 0;
        grid-template-columns: repeat(2, 1fr);

        @media (max-width: 1300px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 1023px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 676px) {
          grid-template-columns: repeat(1, 1fr) !important;
        }

        @media (max-width: 1500px) {
          column-gap: 34px;
          margin: 0;
        }

        & li {
          width: 50%;
          min-width: 100%;
          margin: 0;
          border: none;
          box-shadow: none;
          padding: 0;
          cursor: pointer;
        }
      }

      & button {
        cursor: pointer;
        background-color: var(--successColor);
        color: var(--matterColorLight);
        border: 1px solid #3d5a81;
        border-radius: 2px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 23px;
        text-align: center;
        text-transform: capitalize;
        margin-top: 18px;

        &:disabled {
          display: none;
        }
      }
    }

    & .clearFilter {
      margin: 10px 0;
    }

    & .selectCategoryData {
      & ul {
        margin-bottom: 34px;
        display: flex;
        flex-direction: row;
        scroll-snap-type: x mandatory;
        overflow-x: auto;
        -webkit-box-flex: 1;
        flex-grow: 1;
        padding-bottom: 10px;

        &::-webkit-scrollbar {
          height: 6px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
        }
      }

      & :global(.ais-RefinementList-item--selected) {
        background: #fff8f2;
        border: 1px solid #4661ed;
        border-radius: 100px;
        transform: matrix(1, 0, 0, 1, 0, 0);

        & label {
          & span {
            color: #4661ed;
          }
        }
      }

      & li {
        background: #ffffff;
        border: 1px solid #212121;
        padding: 8px 20px;
        border-radius: 100px;
        margin-right: 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #212121;
        min-width: fit-content;
        cursor: pointer;

        & label {
          margin-bottom: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;

          & span {
            &:nth-child(3) {
              border-radius: 50%;
              width: 20px;
              height: 20px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  & .rightPanel {
    width: 50%;
    position: relative;
    height: 100%;
    overflow: hidden;

    @media (max-width: 1400px) {
      width: 40%;
    }

    @media (max-width: 1300px) {
      display: none;
    }

    & .mapRightBar {
      position: sticky;
      right: 0;
      width: 100%;

      &>div {
        &>div {
          height: 100vh;
          width: 100%;

          &>div {
            &>div {
              &>div {
                &:nth-child(8) {
                  &>div {
                    right: 48px;
                    left: unset !important;
                    bottom: 98px;
                    top: unset !important;

                    &>div {
                      &>div {
                        box-shadow: 0px 4px 8px rgba(35, 38, 59, 0.25) !important;
                        border-radius: 8px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    & .rangeSliderBox {
      position: absolute;
      left: 43px;
      margin-right: auto;
      width: 575px;
      z-index: 9;
      bottom: 35px;
      background: #ffffff;
      box-shadow: 0px 3px 4px rgb(0 0 0 / 14%), 0px 3px 3px rgb(0 0 0 / 12%),
        0px 1px 8px rgb(0 0 0 / 20%);
      border-radius: 8px;
      padding: 22px 30px;

      & .sliderHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        & .radioName {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #212121;
        }
      }
    }

    & .searchMapInput {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 628px;
      z-index: 9;
      top: 35px;

      @media (max-width: 1500px) {
        width: 70%;
      }

      & form {
        & button {
          &:nth-child(2) {
            left: 1.3rem;
            /* background-image: url(../../assets/search-location.png); */
            background-size: 17px;
            background-repeat: no-repeat;
            background-position: center;
            height: 30px;

            & svg {
              /* width: 21px;
              height: 21px; */
              display: none;

              & path {
                fill: #23263b;
              }
            }
          }
        }

        & input {
          height: 51px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #939393;
          padding: 14px 14px 14px 0;
          background: #ffffff;
          /* border: 0.5px solid #939393;
          box-shadow: 0px 3px 4px rgb(0 0 0 / 14%), 0px 3px 3px rgb(0 0 0 / 12%),
            0px 1px 8px rgb(0 0 0 / 20%); */
          border-radius: 4px;
        }
      }
    }
  }
}

.ball {
  position: absolute;
  border-radius: 50px;
  width: 33px;
  height: 33px;
  top: 2px;
  left: 4px;
  transition: transform 0.3s ease;
  background-color: #ffffff;

  @media (max-width: 768px) {
    /* width: 90px;
    height: 45px;
    top: -3px;
    left: -1px; */
  }

  &.toggled {
    transform: translateX(63%);
  }
}

.mapToogleButton {
  display: flex;
  gap: 20px;
  align-items: center;

  @media (max-width: 1300px) {
    display: none;
  }

  & .mapLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #595858;
  }
}

.checkboxWrapper {
  cursor: pointer;
  position: relative;
  border-radius: 50px;
  font-family: var(--font-familyLite);
  width: 64px;
  height: 39px;
  border: 1px solid #000000;
  background: rgb(0 0 0);

  @media (max-width: 768px) {

    /* height: 40px; */
    .breadcrumbsBox {
      &>div {
        display: flex;
        flex-wrap: wrap;
        row-gap: 5px;
      }
    }
  }
}

.filtersList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media (max-width: 768px) {
    padding-top: 30px;
    grid-template-columns: repeat(1, 1fr);
  }

  & .filterBox {
    & .labelName {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #4a4a4a;
      margin-bottom: 6px;
    }

    & select {
      background-size: 12px;
      background-position: 97% 8px;
      border: 0.5px solid #939393;
      border-radius: 25px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #939393;
      padding: 9px 10px;

      /* width: 140px; */
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.bottomButton {
  margin-top: 20px;

  & .clearLink {
    & button {
      text-decoration: none;
      color: #4661ed;
      cursor: pointer;
      border: none;
      border-radius: 100px;
      border: 1px solid #e6e6e6;
      background: #fff;
      color: #4a4a4a;
      padding: 12px 19px;
      margin: 0 auto;
      display: block;
      width: auto;
    }
  }
}

.navLinksBox {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
  margin-top: 29px;

  & nav {
    &>div {
      &:nth-child(7) {
        display: none;
      }

      &:nth-child(6) {
        display: none;
      }

      &:nth-child(5) {
        display: none;
      }
    }

    /* background-color: red; */
    &::-webkit-scrollbar {
      height: 7px;
      border-radius: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  & a {
    width: auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #595858;

    &>span {
      padding-left: 0;
    }
  }
}

.searchTabs {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
  margin-top: 29px;
  display: flex;

  @media (max-width: 768px) {
    border: none;
    padding: 0;
    margin: 0;
  }

  & ul {
    display: flex;

    & :global(.ais-RefinementList-item--selected) {
      & span {
        font-weight: 700;
        color: #4661ed;
      }
    }

    & li {
      margin-right: 20px;

      & label {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #595858;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 10px;
      }

      & span {
        &:nth-child(3) {
          border-radius: 50%;
          width: 20px;
          height: 20px;
          position: relative;
          display: flex;
          display: none;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  & nav {
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    &>div {
      @media (max-width: 768px) {
        margin-left: 0;
        margin-right: 15px;
      }
    }

    &::-webkit-scrollbar {
      height: 7px;
      border-radius: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  & a {
    width: auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #595858;

    &>span {
      padding-left: 0;
    }
  }
}

.ball {
  position: absolute;
  border-radius: 50px;
  width: 33px;
  height: 33px;
  top: 2px;
  left: 4px;
  transition: transform 0.3s ease;
  background-color: #ffffff;

  @media (max-width: 768px) {
    /* width: 90px;
    height: 45px;
    top: -3px;
    left: -1px; */
  }

  &.toggled {
    transform: translateX(63%);
  }
}

.checkboxWrapper {
  cursor: pointer;
  position: relative;
  border-radius: 50px;
  font-family: var(--font-familyLite);
  width: 64px;
  height: 39px;
  border: 1px solid #56889f;
  background: #56889f;

  @media (max-width: 768px) {
    /* height: 40px; */
  }
}

.keywordFilter {
  position: relative;

  & button {
    background-color: #fff;
    border: none;
    cursor: pointer;
    width: 38px;
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translate(-50%, -50%);
  }
}

.Resultcontainer {
  flex: 1;
  margin-top: 50px;

  &.smallMargin {
    margin-top: 0px;
  }
}

.filterWrapperModal {
  &>div {
    &>div {
      padding-top: 80px !important;
    }
  }
}

.toggleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.searchFieldWrapper {
  & :global(.ais-SearchBox-form) {
    display: flex;
    max-width: 400px;
    margin: 0 auto;
    position: relative;

    & input {
      min-width: 300px;
      padding: 4px 16px 3px 50px !important;
    }

    & :global(.ais-SearchBox-submit) {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 40px;
      border: 0;
      border-right: 1px solid #ddd;

      & svg {
        height: 16px;
        width: 16px;
      }
    }
  }
}

.toggleLabel {
  color: #5b5a5a;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.305px;
  padding-bottom: 6px;
  display: flex;
  justify-content: center;
}

.label {
  color: #b2b2b2;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.305px;
  padding: 0;
  margin: 0;
}

.activeLabel {
  color: #c8814d;
}

:global(.react-switch-bg) {
  background: #c8814d !important;
}

:global(.ais-RefinementList-checkbox) {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid var(--Black, #000);
  background: #fff;

  &:focus {
    box-shadow: none !important;
  }
}

:global(.ais-Menu-link) {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  align-items: center;
}

:global(.ais-Panel) {
  /* border-bottom: 1px solid #d1d5db; */
}

:global(.container-header) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  margin-top: 42px;
  position: relative;

  @media (max-width: 767px) {
    margin-top: 32px;
    margin-bottom: 1.75rem;
  }
}

:global(.ais-Hits-list) {
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: repeat(4, 1fr); */
  padding: 0;
  gap: 44px;
  column-gap: 27px;
  @media (max-width: 1450px) {
    gap: 32px;
}
  /* @media(max-width:1360px) {
    grid-template-columns: repeat(3, 1fr);
  }
     @media(max-width:960px) {
       grid-template-columns: repeat(2, 1fr);
     }
  @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    width: 100%;
  } */
}

:global(.ais-Hits--empty) {
  min-height: 60vh;
}

:global(.ais-Hits-item) {
  list-style: none;
  width: calc(25% - 12px);
  flex-direction: column;
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
  box-shadow: 0px 14px 24px 0px rgba(0, 0, 0, 0.14);
  padding: 0 !important;

  @media (max-width: 1260px) {
    width: calc(33% - 12px);
  }

  @media (max-width: 1023px) {
    width: calc(50% - 12px);
  }

  @media (max-width: 767px) {
    width: calc(100% - 0px);
  }

  & a {
    width: 100%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

:global(.ais-Pagination-list) {
  justify-content: center;
  margin-top: 45px !important;
  gap: 18px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;
}

:global(.ais-Pagination-link) {
  border: 0 !important;
  background-image: none !important;
  background-color: transparent;
  color: #0E1319 !important;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  box-shadow: unset !important;
}

:global(.ais-Pagination-item--disabled) {
  & span {
    color: gray !important;
    cursor: not-allowed;
  }
}
.toggleWrapper {
  padding-top: 6px;
}
.show5card {
  & ol{
    column-gap: 27px;
  }
  & :global(.ais-Hits-item) {
    /* width: 100%; */
    width: calc((100% - (27px * 3)) / 4);

    @media (max-width: 1360px) {
      width: calc((100% - (27px * 2)) / 3);
    }

    @media (max-width: 1023px) {
      width: calc((100% - (27px * 1)) / 2);
    }

    @media (max-width: 576px) {
      width: 100%;
    }

    & a {
      width: 100%;

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

:global(.ais-HitsPerPage-select) {
  color: var(--Black, #000) !important;
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  /* 142.857% */
  border: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  background-size: 17px, auto !important;
  padding-left: 0 !important;
  background-image: unset !important;

  &:hover {
    border: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    background-image: unset !important;
  }

  &:focus {
    border: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    background-image: unset !important;
  }
}

:global(.ais-SortBy-select) {
  /* 142.857% */
  border: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  background-size: 17px, auto !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M4.51605 7.54794C4.95205 7.10194 5.55905 7.06694 6.09205 7.54794L10.0001 11.2949L13.9081 7.54794C14.4411 7.06694 15.0491 7.10194 15.4821 7.54794C15.9181 7.99294 15.8901 8.74494 15.4821 9.16294C15.0761 9.58094 10.787 13.6649 10.787 13.6649C10.57 13.8879 10.285 13.9999 10.0001 13.9999C9.71505 13.9999 9.43005 13.8879 9.21105 13.6649C9.21105 13.6649 4.92405 9.58094 4.51605 9.16294C4.10805 8.74494 4.08005 7.99294 4.51605 7.54794Z' fill='%236B7280'/%3E%3C/svg%3E") !important;
  background-position: right;

  &:hover {
    border: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M4.51605 7.54794C4.95205 7.10194 5.55905 7.06694 6.09205 7.54794L10.0001 11.2949L13.9081 7.54794C14.4411 7.06694 15.0491 7.10194 15.4821 7.54794C15.9181 7.99294 15.8901 8.74494 15.4821 9.16294C15.0761 9.58094 10.787 13.6649 10.787 13.6649C10.57 13.8879 10.285 13.9999 10.0001 13.9999C9.71505 13.9999 9.43005 13.8879 9.21105 13.6649C9.21105 13.6649 4.92405 9.58094 4.51605 9.16294C4.10805 8.74494 4.08005 7.99294 4.51605 7.54794Z' fill='%236B7280'/%3E%3C/svg%3E") !important;
  }

  &:focus {
    border: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M4.51605 7.54794C4.95205 7.10194 5.55905 7.06694 6.09205 7.54794L10.0001 11.2949L13.9081 7.54794C14.4411 7.06694 15.0491 7.10194 15.4821 7.54794C15.9181 7.99294 15.8901 8.74494 15.4821 9.16294C15.0761 9.58094 10.787 13.6649 10.787 13.6649C10.57 13.8879 10.285 13.9999 10.0001 13.9999C9.71505 13.9999 9.43005 13.8879 9.21105 13.6649C9.21105 13.6649 4.92405 9.58094 4.51605 9.16294C4.10805 8.74494 4.08005 7.99294 4.51605 7.54794Z' fill='%236B7280'/%3E%3C/svg%3E") !important;
  }
}

.shortWrapper {
  display: flex;
  align-items: center;
}

:global(.ais-Pagination-item) {
  width: 30px;
  text-align: center;
  border-bottom: 3px solid transparent;
  cursor: pointer;

  & a {
    text-decoration: none;
    height: 100%;
    width: 100%;
    display: block;
    padding: 15px 0;
  }
}

:global(.ais-Pagination-item--selected) {
  border-bottom: 3px solid #0E1319;
}

:global(.ais-Pagination-item--previousPage) {
  position: absolute;
  left: 38px;

  @media (max-width: 767px) {
    left: 12px;
  }

  & span {
    font-size: 30px !important;
  }

  & a {
    font-size: 30px !important;
  }
}

:global(.ais-Pagination-item--nextPage) {
  position: absolute;
  right: 38px;

  @media (max-width: 767px) {
    right: 12px;
  }

  & span {
    font-size: 30px !important;
  }

  & a {
    font-size: 30px !important;
  }
}

:global(.ais-RefinementList-item--selected .ais-RefinementList-checkbox) {
  background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 512 512' class='absolute top-0.5 -left-0.5 h-5 w-5 text-black xl:h-5 xl:w-5 2xl:top-0 2xl:-left-0.5 2xl:h-%5B23px%5D 2xl:w-%5B23px%5D block' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M400 48H112a64.07 64.07 0 00-64 64v288a64.07 64.07 0 0064 64h288a64.07 64.07 0 0064-64V112a64.07 64.07 0 00-64-64zm-35.75 138.29l-134.4 160a16 16 0 01-12 5.71h-.27a16 16 0 01-11.89-5.3l-57.6-64a16 16 0 1123.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0124.5 20.58z'%3E%3C/path%3E%3C/svg%3E") !important;
  border: 0;
  background-size: 22px;
}

:global(.ais-RefinementList-labelText) {
  color: var(--Black, #000);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.searchFilterUpdate {
  /* max-width: 785px; */
  /* margin: 0 auto; */
  display: flex;
  /* justify-content: center; */
  align-items: flex-end;
  gap: 20px;

  flex-wrap: wrap;
  /* padding-left: 24px; */
  padding-right: 24px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
    margin-top: 0px;
    flex-wrap: nowrap;
    max-width: calc(100vw - 44px);
    overflow-x: auto;
    justify-content: flex-start;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: #f9d3c7;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #ffaa8c;
    }
  }

  &>div {
    &:nth-child(1) {
      @media (max-width: 460px) {
        width: 100%;
      }
    }

    &:nth-child(2) {
      @media (max-width: 600px) {
        width: 75%;
      }
    }
  }
}

.filterBoxUpdate {
  & .labelNameUpdate {
    /* display: none; */
    margin-bottom: 6px;
    text-align: center;
  }

  & select {
    padding: 7px 20px 7px 20px !important;
    border-radius: 4px;
    background-image: none;
    text-align: center !important;
    line-height: 1;
    min-height: 40px;
    cursor: pointer;
    min-width: 183px;
  }
}

.filterBoxMore {
  border-radius: 4px;

  padding: 7px 20px 7px 20px;
  line-height: 1;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center right 16px;
  background-size: 10px;
  text-align: center !important;
  margin: 0;
  background-color: #fff;
  border: 1px solid #d8dce6;
  outline: none;
  transition: all ease-in-out 100ms;
  box-shadow: 0 2px 3px 0 rgba(216, 220, 230, 0.7);
  appearance: none;
  background-image: none;
  color: #4a4a4a;
  font-weight: 400;
  cursor: pointer;

  @media (max-width: 768px) {
    white-space: nowrap;
  }
}

.listingCardMain {
  /* background-color: red;
  max-width: 366px;
  width: 100%; */

  & .listingCardWrapper {
    border-radius: 0px !important;
    background: #fff !important;
    box-shadow: none !important;
  }
}

.pagination {
  max-width: 864px;
  height: 64px;
  margin: 0 auto;
  margin-top: 236px;
  background: #fff;

  & ul {
    margin: 0;
    display: flex !important;
    justify-content: space-between;
    align-items: center !important;
    height: 100%;

    & li {
      width: 29px !important;
      min-width: auto !important;
      display: flex;
      justify-content: center !important;
    }

    & :global(.ais-Pagination-item--firstPage) {
      display: none !important;
    }

    & :global(.ais-Pagination-item--previousPage) {
      position: relative;
      height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;

      & span {
        display: none;
      }

      & a {
        font-size: 20px;
        opacity: 0;
        position: relative;
        z-index: 3;
        width: 30px;
        height: 30px;
      }
    }

    & :global(.ais-Pagination-item--previousPage)::after {
      content: ' ';
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.4 0c.17 0 .34.065.458.192a.507.507 0 0 1-.07.764L1.529 6.5l7.259 5.545a.507.507 0 0 1 .07.763.647.647 0 0 1-.846.064l-7.8-5.958A.523.523 0 0 1 0 6.5c0-.16.077-.31.212-.414l7.8-5.958A.642.642 0 0 1 8.4 0Z' fill='%23666241'/%3E%3Cpath d='M8.4 0c.17 0 .34.065.458.192a.507.507 0 0 1-.07.764L1.529 6.5l7.259 5.545a.507.507 0 0 1 .07.763.647.647 0 0 1-.846.064l-7.8-5.958A.523.523 0 0 1 0 6.5c0-.16.077-.31.212-.414l7.8-5.958A.642.642 0 0 1 8.4 0' stroke='%23666241'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h9v13H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    & :global(.ais-Pagination-item--nextPage) {
      position: relative;
      height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;

      & a {
        font-size: 20px;
        opacity: 0;
        position: relative;
        z-index: 3;
        width: 30px;
        height: 30px;
      }

      & span {
        display: none;
      }
    }

    & :global(.ais-Pagination-item--nextPage)::after {
      content: ' ';
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1942_342)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.599942 13C0.429541 13 0.26094 12.935 0.141539 12.8077C-0.0720627 12.5802 -0.0402625 12.2384 0.212339 12.0445L7.47059 6.49995L0.212339 0.955407C-0.0402625 0.761489 -0.0720627 0.419695 0.141539 0.191652C0.35634 -0.0369336 0.734343 -0.0645588 0.987545 0.127734L8.7876 6.08611C8.9226 6.18957 9 6.3407 9 6.49995C9 6.6592 8.9226 6.81033 8.7876 6.91379L0.987545 12.8722C0.874744 12.9577 0.736743 13 0.599942 13Z' fill='%23666241'/%3E%3Cpath d='M0.599942 13C0.429541 13 0.26094 12.935 0.141539 12.8077C-0.0720627 12.5802 -0.0402625 12.2384 0.212339 12.0445L7.47059 6.49995L0.212339 0.955407C-0.0402625 0.761489 -0.0720627 0.419695 0.141539 0.191652C0.35634 -0.0369336 0.734343 -0.0645588 0.987545 0.127734L8.7876 6.08611C8.9226 6.18957 9 6.3407 9 6.49995C9 6.6592 8.9226 6.81033 8.7876 6.91379L0.987545 12.8722C0.874744 12.9577 0.736743 13 0.599942 13' stroke='%23666241'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1942_342'%3E%3Crect width='9' height='13' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    & :global(.ais-Pagination-item--selected) {
      position: relative;

      &:after {
        content: '';
        width: 29px;
        height: 3px;
        background-color: #1d1d1f;
        position: absolute;
        bottom: -20px;
        right: 0;
        left: 0;
      }
    }

    & :global(.ais-Pagination-item--page) {
      & a {
        color: #1d1d1f;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 150% */
        letter-spacing: -0.348px;
      }
    }
  }
}

.mapBox {
  width: 100%;
  height: 100vh;

  &>div {
    width: 100%;
    height: 100%;

    & :global(.ais-GeoSearch-map) {
      height: 100%;
    }

    &>div {
      width: 100%;
      /* height: 100%; */
    }
  }
}

.mainMarker {
  & .lol {
    position: relative;
    background-color: red;
  }
}

.lol:hover .hoverData {
  visibility: visible;
}

.lol:hover {
  z-index: 100000 !important;
}

.hoverData {
  visibility: hidden;
  width: 250px;
  background-color: #fff;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3));
  border-radius: 6px;
  padding: 18px;
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 50%;
  margin-left: -120px;

  & .cardHeading {
    color: #7c3aed;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.305px;
    margin: 0;
    padding: 0;
    white-space: normal;
  }

  & .cityName {
    color: #4a4a4a;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    padding: 0;
    white-space: normal;
  }

  & .addressDetail {
    color: #4a4a4a;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.2px;
    margin: 0;
    padding: 0;
    white-space: normal;
  }
}

.filterWrapper {
  width: 261px;

  @media (max-width: 767px) {
    width: 100%;
  }

  &.filterDesktop {
    @media (max-width: 767px) {
      display: none;
    }
  }
}

.faqsec {
  padding: 0px;

  @media (max-width: 767px) {
    padding: 0 0px;
  }
}

.faqwrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  & :global(.accordion) {}

  & :global(.ais-RefinementList-count) {
    display: none !important;
  }

  & label {
    padding-bottom: 0 !important;
  }

  & .faqItemCount {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #222a32;
    padding-right: 8px;
    border-right: 3px solid #1aae9f;
    margin-right: 17px;
    width: 40px;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 27px;
      min-width: 40px;
    }
  }

  /* & :global(.accordion__item + .accordion__item) {
    border-top: 1px solid #d1d1d1;
  } */
  & :global(.accordion__button) {
    cursor: pointer;
    padding: 5px 20px 5px 0;
    width: 100%;
    text-align: left;
    border: none;
    position: relative;
    display: flex;
    align-items: center;
    color: var(--Black, #000);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    /* 150% */
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  & :global(.accordion__button:hover) {
    /* background-color: #ddd; */
  }

  & :global(.accordion__button:before) {
    display: inline-block;
    content: '';
    height: 27px;
    width: 22px;
    position: absolute;
    right: 0px;
    top: 0px;
    transform: scale(1.2);
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 24 24' class='text-xl' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'%3E%3C/path%3E%3Cpath d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'%3E%3C/path%3E%3C/svg%3E");
  }

  & :global(.accordion__button[aria-expanded='true']::before),
  & :global(.accordion__button[aria-selected='true']::before) {
    width: 30px;
    background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 24 24' class='text-xl' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'%3E%3C/path%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'%3E%3C/path%3E%3C/svg%3E");
  }

  [hidden] {
    display: none;
  }

  & :global(.accordion__panel) {
    padding: 0px 10px 0px 0px;

    & ul {
      list-style: disc;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  /* -------------------------------------------------- */
  /* ---------------- Animation part ------------------ */
  /* -------------------------------------------------- */
  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

:global(.ais-Panel) {
  margin-bottom: 0;
}

:global(.accordion__item) {
  border-bottom: 1px solid #d1d5db;
  padding: 10px 0;
}

.searchBox {
  /* position: absolute; */
  top: 0px;
  left: 26px;
  z-index: 2;
  max-width: 682px;
  width: 100%;
  padding-top: 0px;
  border-radius: 10px;
  border: 1px solid var(--Pebble, #f7f6f5);
  background: #fff;
  overflow: hidden;

  @media (max-width: 1450px) {
    max-width: 400px;
  }

  & input {
    height: 58px;
    color: var(--Light, #3d4a5b);
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;

    &::placeholder {
      color: #b2b2b2;
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1px;
    }
  }

  & label {
    & button {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px 19px;
      background-image: url("data: image/svg+xml, %3Csvg%20width%3D%2227%22%20height%3D%2219%22%20viewBox%3D%220%200%2027%2019%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M11.8929%2012L1.50422%2017.2411%22%20stroke%3D%22%234A4A4A%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3Ccircle%20cx%3D%227.5%22%20cy%3D%227.5%22%20r%3D%227.5%22%20transform%3D%22matrix(-1%200%200%201%2026%201)%22%20stroke%3D%22%234A4A4A%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");

      & svg {
        display: none !important;
      }
    }
  }

  @media (max-width: 1200px) {
    top: 50px;
    padding-top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    top: 44px;
    left: 20px;
    right: 20px;
    width: 100%;
    z-index: 9;
  }

  & :global(.aa-Form) {
    border: 0 !important;
    box-shadow: unset !important;
  }

  & :global(.ais-SearchBox-reset) {
    right: 2rem !important;
    border: 0;
  }

  & form {
    &:before {
      left: unset !important;
      right: 0;
      width: 24px !important;
      background-repeat: no-repeat;
      transform: scale(1.2);
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.6625 14.9625L13.3763 10.6538C14.1918 9.51577 14.6287 8.15005 14.625 6.75C14.625 3.02625 11.5988 0 7.875 0C4.15125 0 1.125 3.02625 1.125 6.75C1.125 10.4738 4.15125 13.5 7.875 13.5C9.3375 13.5 10.665 13.0388 11.7788 12.2513L16.0875 16.5375C16.3013 16.7625 16.5938 16.875 16.875 16.875C17.1562 16.875 17.46 16.7738 17.6625 16.5375C17.7668 16.4334 17.8495 16.3098 17.906 16.1737C17.9624 16.0376 17.9915 15.8917 17.9915 15.7444C17.9915 15.597 17.9624 15.4511 17.906 15.315C17.8495 15.179 17.7668 15.0553 17.6625 14.9513V14.9625ZM7.875 12.0375C4.96125 12.0375 2.5875 9.66375 2.5875 6.75C2.5875 3.83625 4.96125 1.4625 7.875 1.4625C10.7887 1.4625 13.1625 3.83625 13.1625 6.75C13.1625 9.66375 10.7887 12.0375 7.875 12.0375Z' fill='black'/%3E%3C/svg%3E%0A") !important;
    }
  }

  & input {
    border: 0 !important;
    border-radius: 0 !important;

    box-shadow: none !important;
    font-family: Inter;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 1px;
    color: #3d4a5b;
    @media (max-width: 1023px) {
      padding: 3px 16px 3px 0px !important;
    }

    &::placeholder {
      font-family: var(--fontFamily);
      font-family: Inter;
      font-size: 16px;
      font-style: italic;
      font-weight: 500;
      line-height: 19.36px;
      letter-spacing: 1px;
      text-align: left;
      color: #3d4a5b;
    }

    &:focus {
      box-shadow: none !important;
      border-bottom: 0px solid #1f2937 !important;
    }
  }
}

.searchBoxTopbar {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 12px;
  max-width: unset;
}

:global(.ais-Stats-text) {
  font-weight: 600;
}

:global(.ais-RefinementList-list) {
  max-height: 16rem;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background: #edf5fa;
  }

  &::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 6px;
    outline: 1px solid #fff;
  }
}

.searchResults {
  color: #4a4a4a;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 123.077% */
  letter-spacing: -0.2px;
}

.noDataFoundWrapper {
  @media (max-width: 576px) {
    display: none;
  }
}

:global(.header-desktop) {
  @media (max-width: 767px) {
    display: none;
  }
}

:global(.header-mobile) {
  display: none;

  @media (max-width: 767px) {
    display: flex;
  }
}

.noDataFound {
  position: absolute;
  font-size: 1.875rem;
  line-height: 2.25rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  white-space: nowrap;
  top: 330px;

  @media (max-width: 1200px) {
    left: 0;
  }

  &.noDataFoundleft {
    @media (max-width: 1200px) {
      left: 0;
    }
  }
}

.arrow {
  cursor: pointer;
}

.paginationWrapper {
  list-style: none;
  /* Remove default list bullets */
  align-items: center;
  display: flex;
  /* Arrange page links horizontally */
  justify-content: center;
  /* Center the links within the list */
  margin: 40px auto;
  /* Add some spacing above and below */
  gap: 24px;

  & .paginationWrapper {
    margin: 0 5px;
    /* Add spacing between page links */
  }

  a {
    display: flex;
    /* Make links take up full width of list items */
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    border-radius: 50%;
    color: #000000;

    &:hover {
      background-color: #fff !important;
      text-decoration: none;
    }
  }

  a:hover {
    background-color: #e5e5e5;
    /* Change background color on hover */
  }
}

.activePage {
  & a {
    background-color: #000000;
    border-radius: 50%;
    color: #fff;

    &:hover {
      background-color: #000000 !important;
      text-decoration: none;
    }
  }
}

.refinementList {
  display: 'none';
  text-decoration: 'none';
  outline: 'none';
}

.searchFilter {
  display: flex;
  gap: 30px;
  max-width: 1520px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: flex-end;

  & .filterDesktop {
    flex: 1;
  }
}

.category {
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  background: #fff;
  color: #0e1319;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 123.077% */
  letter-spacing: -0.2px;
  padding: 12px 18px;
  cursor: pointer;
  display: flex;
  align-items: center;

  & select {
    padding: 0 20px 0 10px;
    color: #0e1319;
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 123.077% */
    letter-spacing: -0.2px;
  }
}

.categoryWrapper {
  position: relative;

  @media (max-width: 767px) {
    width: 100%;

    & button {
      width: 100%;
    }
  }

  & .popupWrapper {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    position: absolute;
    background-color: #fff;
    z-index: 2;
    top: 100%;
    left: 0;
    min-width: 200px;
    padding: 10px;

    @media (max-width: 767px) {
      right: 0;
    }
  }

  & ul {
    margin: 0;
    max-height: 300px;
    overflow-y: auto;
  }

  & :global(.ais-RefinementList-item--selected) {
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.139);
    border-left: 4px solid #00000066;
    padding-left: 6px;
    background-color: #eeeeee;
  }

  & :global(.ais-RefinementList-item) {
    & span {
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
}

:global(.ais-RefinementList-label) {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

:global(.ais-SearchBox-form) {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 15px 20px 15px 50px;
  position: relative;
}

:global(.ais-SearchBox-submit) {
  position: absolute;
  left: 16px;
  border: 0;
  top: 20px;

  @media (max-width: 767px) {
    left: 8px;
    top: 16px;
  }

  & svg {
    width: 30px;
    height: 30px;

    @media (max-width: 767px) {
      width: 20px;
      height: 20px;
    }
  }

  & input {
    padding-left: 50px;
  }
}

.flex {
  display: flex;

  & .label {
    margin: 5px;
  }
}

.loader_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #C8814D;
  /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.clearBtn {
  width: 120px;
}

.clearBtn button {
  min-height: 42px !important;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
  &:disabled{
    color: #0e1319;
  }
}